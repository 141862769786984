import * as React from "react"
import Seo from "../components/seo"
import Nav from "../components/nav"
import Intro from "../components/intro"
import Details from "../components/details"
import Menu from "../components/menu"
import About from "../components/about"
import Contact from "../components/contact"
import Footer from "../components/footer"

const IndexPage = () => {
  return (
    <>
      <Seo title="湘南鵠沼・片瀬江ノ島　こころとからだのメンテナンス　ほぐしのエリー" />
      <Nav />
      <main>
        <Intro />
        <Details />
        <About />
        <Menu />
        <Contact />
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
