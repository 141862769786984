// extracted by mini-css-extract-plugin
export var detailsBase = "details-module--detailsBase--c2527";
export var container = "details-module--container--5616c";
export var sectionFlex = "details-module--section-flex--4c02e";
export var sectionFlex2 = "details-module--section-flex2--10f76";
export var sectionFlexItem = "details-module--section-flex-item--67605";
export var black = "details-module--black--7bbca";
export var imagePicTop = "details-module--image-pic-top--2f8d6";
export var imagePicBottom = "details-module--image-pic-bottom--b40ae";
export var imagePicTop2 = "details-module--image-pic-top2--c5e32";
export var imagePicBottom2 = "details-module--image-pic-bottom2--4f662";
export var white = "details-module--white--0618d";
export var itemContents = "details-module--item-contents--d9f89";
export var itemContents2 = "details-module--item-contents2--28dd3";