import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  base,
  container,
  picFlex,
  menuFlexPic,
  left,
} from "../components/menu.module.css"

const Menu = () => {
  return (
    <>
      <article className={base} id="menu">
        <div className={container}>
          <div className={picFlex}>
            <div className={menuFlexPic}>
               <StaticImage
                  alt="bodypic"
                  src="../images/image3.jpg"
                />
              <p><b>スタイルアップトレーニング 60分×１回<br/>施術後の変化</b></p>
              <p className={left}><small>腰が前にせり出す姿勢から、前もものハリ感がひどかったのですが、60分後、きれいにもものハリがとれることで、頭のポジションが整い、綺麗に楽に立てるようになりました。</small></p>
            </div>
            <div className={menuFlexPic}>
              <StaticImage
                alt="bodypic3"
                src="../images/image0.jpg"
              />
              <p><b>スタイルアップトレーニング 60分 <br/>１年間以上継続後の変化</b></p>
              <p className={left}><small>立ち姿勢が全く違います。初回は、腰痛を訴えてご来店くださいました。腰痛の原因である姿勢を改善したことで、立ち姿勢が別人のように変わりました。</small></p>
            </div>
            <div className={menuFlexPic}>
               <StaticImage
                  alt="bodypic2"
                  src="../images/oil.jpg"
                />
              <p><b>ボディオイルトリートメント 80分間<br/>施術後の変化</b></p>
              <p className={left}><small>肩こりの原因となる巻き肩が解消されバストトップとヒップトップに高さが出ました。</small></p>
            </div>
            </div>
        </div>
      </article>
    </>
  )
}

export default Menu
