// extracted by mini-css-extract-plugin
export var base = "menu-module--base--bbcb2";
export var container = "menu-module--container--07ab7";
export var white = "menu-module--white--a8ca1";
export var blue = "menu-module--blue--df7ef";
export var menuFlex = "menu-module--menu-flex--772be";
export var picFlex = "menu-module--pic-flex--af94c";
export var menuFlexItem = "menu-module--menu-flex-item--9b382";
export var menuFlexPic = "menu-module--menu-flex-pic--f2840";
export var menuWrapper = "menu-module--menu-wrapper--7dbae";
export var menuContents = "menu-module--menu-contents--e1f65";
export var blueLine = "menu-module--blue-line--bd5ea";
export var left = "menu-module--left--a2e73";