import * as React from 'react'
import {Link} from 'gatsby'
import {
  white,
  heroImage,
  heroContainer,
  container,
} from './intro.module.css'

const Intro = () => {
  return(
    <div>
      <article className={heroImage}>
        <section className={heroContainer}>
          <h2 className={white}>肌も体もキレイでいたいから。<br/>まだ、あきらめない。</h2>
          <br/>
          <Link to="#contact" className="primary">ご予約はこちら</Link>
        </section>
      </article>
      <div>
        <h3 className={container}>
          肌やからだの不調を感じやすくなる４０代。<br/>
          時には心まで不安になることも。<br/>
          自分の為に少しだけ時間をつくることで、ご機嫌な日々を取り戻せます。<br/>
          仕事や家族が優先、日々の忙しさで自分が後回しになりがちな頑張るあなたに、<br/>ほぐしのエリーは誰よりもまっすぐに向き合います。<br/>
        </h3>
      </div>
    </div>
  )
}

export default Intro
