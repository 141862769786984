import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  heroImage,
  heroContainer,
  centerPic,
  inputForm,
  inputText,
  formButton,
} from './contact.module.css'

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = event => {
    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "title":
        setTitle(event.target.value);
        break;
      case "message":
        setMessage(event.target.value);
        break;
      default:
        console.log("key not found");
    }
  }

  const canSubmit = () => {
    if (name === "") return true;
    if (email === "") return true;
    if (title === "") return true;
    if (message === "") return true;

    return false;
  }

  return(
    <>
      <article className={heroImage} id='contact'>
        <section className={heroContainer}>
          <h2>
          ご予約 / お問い合わせ
          </h2>
          <p><b>【営業時間】</b><br />月〜金　9:00 - 17:00、土　午前中（要相談）</p>
          <p><b>【場所】</b>神奈川県藤沢市片瀬海岸<br />
            江ノ電・湘南海岸公園駅、小田急線・片瀬江ノ島駅　両駅徒歩１０分前後です。<br />(詳細は、予約時にお伝えします。)</p>
          <hr />
          <p><b>【LINEでのご予約・お問い合わせ】</b><br/><a href="https://lin.ee/O6rWcMb" target="_blank" rel="noreferrer">LINEはこちらをクリック</a>または下記QRコードから<br/>
          <StaticImage
              alt="reservation"
            src="../images/qr.jpg"
            className={centerPic}
            /></p>
          <hr />
          <p><b>【オンラインでのご予約】</b><br/><a href="https://reservia.jp/reserve/menu/7bbd76324d?start_page=2&is_guest=1" target="_blank" rel="noreferrer">オンライン予約はこちらをクリック</a>または下記QRコードから<br/>
          <StaticImage
              alt="reservation"
            src="../images/QR_173059.png"
            className={centerPic}
            /></p>
          <hr />
          <p><b>【メールフォームでのご予約・お問い合わせ】</b><br/>
            こちらのフォームからお気軽にどうぞ。折り返しメールにて連絡いたしますので、必ず受信可能な設定にしていただきますようお願いいたします。
          </p>
          <form name="Contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/success">
              <input type="hidden" name="form-name" value="Contact" />
              <input type="hidden" name="bot-field" />
              <div>
                <p><small>* すべて入力必須項目です。</small></p>
                  <p><label htmlFor="name">お名前</label><br/>
                  <input type="text" name="name" id="name" className={inputForm} value={name}
                  onChange={handleChange} required/></p>
              </div>
              <div>
                <p><label htmlFor="email">Eメール（半角）</label><br/>
                <input type="email" name="email" pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" className={inputForm} value={email} id="email"
                onChange={handleChange} required/></p>
              </div>
              <div>
                <p><label htmlFor="title">件名</label><br/>
                <input type="title" name="title" className={inputForm} value={title} id="title"
                onChange={handleChange} required/></p>
              </div>
              <div>
                <p>
                  <label htmlFor="message">内容詳細</label><br />
                  <textarea name="message" className={inputText} id="message" value={message} onChange={handleChange} required>
                  </textarea>
                </p>
              </div>
              <button type="submit" className={formButton} disabled={canSubmit()}>送信</button>
            </form>
        </section>
      </article>
    </>
  )
}

export default Contact
