import * as React from 'react'
import {
  heroImage,
  heroContainer,
} from './about.module.css'

const About = () => {
  return(
    <>
      <article className={heroImage}>
        <section className={heroContainer}>
          <h2>
            自己紹介 エリー
          </h2>
          <p>年子兄弟の出産、ワンオペ育児により体調を崩し、そんな時に出会ったのがピラティスでした。<br/>当時の私は姿勢の悪さから肩こり腰痛を引き起こし、産後のホルモンバランスと筋力の崩れから体調も崩すという悪循環に陥っていたのです。<br/>
          実は出産前まで約10年公立中学校の体育教員をしておりました。<br/>
            趣味はスノーボードとアクティブに動いていたこともあり、体力に自信があり、それまで自分の姿勢が悪いなんて思ってもいませんでした。</p>
          <p>
            教育現場からトレーナ兼セラピストへ。<br />
            リハビリが由来のピラティス。家事、育児、仕事。日常の体の使い方を変えるだけでからだが楽になる。そんな思いを伝えたく、都内ピラティススタジオでトレーナーをしていました。グループ、パーソナルレッスンを通して多くのお客様の体を見させて頂く中で、凝り固まった体では運動することがツライ、自分なりに頑張っているのだけど、体型の変化にはつながりづらいという方が多くいらっしゃいました。<br />
            ヨガの先生のような動きができないという経験をされた方もいらっしゃるかと思います。
          </p>
          <p>
            まずはからだの不調やお悩みをお伝えください。<br/>
            もう、歳だから仕方ないと諦めないでください。何歳からでもからだは変わります。<br/>オイルトリートメントと運動の融合。40代からの更年期に負けない、あなたらしい美しさを一緒に作っていきましょう。<br/>
            日々を頑張る全ての女性に、そして子育てに奮闘中のママに、お一人おひとりに合わせた大切な時間を提供させていただきます。
          </p>
        </section>
      </article>
    </>
  )
}

export default About
