import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  detailsBase,
  container,
  sectionFlex,
  sectionFlex2,
  sectionFlexItem,
  itemContents,
  itemContents2,
  imagePicTop,
  imagePicTop2,
  imagePicBottom,
  black,
} from './details.module.css'

const Details = () => {
  return(
    <div className={detailsBase}>
      <article className={container}>
        <section>
          <h2 className={black}>メニュー</h2>
        </section>
       <section className={sectionFlex2}>
          <div className={sectionFlexItem}>
            <h3 className={itemContents2}>スタイルアップボディオイルトリートメント</h3>
            <p className={itemContents2}>高濃度酸素オイルを使い、デコルテ周りと腕をメインにアプローチします。<br />
              ハーブの香りに癒されながら、からだと心の調和を取り本来あなたが持つ美しい姿勢を引き出します。<br />
              眠りが浅い、体が重だるい、後ろ姿や姿勢が気になる、肩こりや首こりのひどい方に特におすすめです。<br />
              ＊香りの苦手な方、妊娠中、授乳中の方には、無香料をお選びいただけます。<br /><br />
              【初回】 110分(カウンセリング20分, 施術90分)  11000円<br />
              【リピート】 90分 11000円, 120分 13200円
            </p>
          </div>
          <div className={sectionFlexItem}>
            <StaticImage
              alt="master"
              src="../images/hero.jpg"
              className={imagePicTop}
            />
          </div>
        </section>
        <section className={sectionFlex2}>
          <div className={sectionFlexItem}>
            <h4>【使用オイル】</h4>
            <p className={itemContents2}>
              HERBAL BODY BEAUTY OIL<br />
              (ハーバルボディビューティーオイル) <br />
              高濃度酸素オイル<br />
              塗るだけで酸素を皮膚から吸収させることができる、世界でたった一つのオイルO2KRAFTに天然ハーブを配合し美肌要素を強化しました。<br />自然治癒力や免疫力そのものに働きかけるハーブの特性を活かしながら「酸素」と合わせたマッサージオイルです。<br />
            </p>
          </div>
          <div className={sectionFlexItem}>
            <StaticImage
              alt="master"
              src="../images/herbal.webp"
              className={imagePicTop}
            />
          </div>
        </section>
        <section className={sectionFlex}>
          <div className={sectionFlexItem}>
            <h3 className={itemContents}>
              スタイルアップトレーニング
            </h3>
            <p className={itemContents}>
              整体＋ピラティス<br/>
              ご自身のからだの癖を知り、ピラティスをベースとした緩やかな運動で姿勢を改善します。<br />
              からだのラインを変えたい、下腹が気になる、リフレッシュしたい、腰痛や膝など体に痛みがある方におすすめです。<br />
              からだの癖を知ることで、体型や体質の改善が期待できます。<br/><br/>
              【初回】 80分  9900円<br />
              【リピート】 60分  7700円,   80分  8800円
            </p>
          </div>
          <div className={sectionFlexItem}>
            <StaticImage
              alt="training"
              src="../images/training.jpg"
              className={imagePicTop}
            />
          </div>
        </section>
        <section className={sectionFlex2}>
          <div className={sectionFlexItem}>
            <h3 className={itemContents2}>キッズボディバランスコーディネート</h3>
            <p className={itemContents2}>お子様の体のお悩み、競技のパフォーマンスアップに。<br />
              現代の生活環境、ゲームやスマホによる子どもの姿勢の崩れも見逃せません。<br />
              姿勢を見直し、体の使い方をお伝えします。スポーツのステップアップに繋がります。<br />
              ＊競技指導は致しません。ご理解ください。<br /><br />
              【料金】50分 4500円
            </p>
          </div>
          <div className={sectionFlexItem}>
            <StaticImage
              alt="junior"
              src="../images/pair2.jpg"
              className={imagePicTop}
            />
          </div>
        </section>
        <section className={sectionFlex}>
          <div className={sectionFlexItem}>
            <h3 className={itemContents}>お客様だけの空間、小さなお子様連れもどうぞ</h3>
            <p className={itemContents}>ママこそ施術を受けてほしい！<br />
              だからこそ、お子様連れ大歓迎です。<br />
              完全プライベート空間ですので、周囲を気にせずお過ごし頂けます。<br />
              お子様のお気に入りのおもちゃとおやつを持っていらしてください。<br />
              ＊予約時にお子様の月齢、年齢をお伝えください。
            </p>
          </div>
          <div className={sectionFlexItem}>
            <StaticImage
              alt="child"
              src="../images/child2.jpg"
              className={imagePicTop2}
            />
            <StaticImage
              alt="room"
              src="../images/room.jpg"
              className={imagePicBottom}
            />
          </div>
        </section>
      </article>
    </div>
  )
}

export default Details
