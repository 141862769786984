import * as React from 'react'
import { Helmet } from "react-helmet"

const Seo = () => {
  return (
    <Helmet
      htmlAttributes={{ lang: 'ja' }}
      title='ほぐしのエリー 湘南鵠沼・片瀬江ノ島 こころとからだのメンテナンス'
      meta={[
        {
          name: `description`,
          content: `湘南鵠沼・片瀬江ノ島　ほぐしのエリーのウェブサイトです。毎日スキンケアをするように、ボディケアを。友人の家に遊びに行くような感覚で気軽に楽しく、体のお手入れしてみませんか？`,
        },
        {
          property: `og:title`,
          content: 'ほぐしのエリー 湘南鵠沼・片瀬江ノ島 こころとからだのメンテナンス',
        },
        {
          property: `og:description`,
          content: '湘南鵠沼・片瀬江ノ島　ほぐしのエリーのウェブサイトです。毎日スキンケアをするように、ボディケアを。友人の家に遊びに行くような感覚で気軽に楽しく、体のお手入れしてみませんか？',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: '../images/logo-blue.png'
        },
        {
          property: `og:url`,
          content: "https://hogushino-eri.com",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `google-site-verification`,
          content: "HyC2zo1i1tOuHK-4j9YFCiUXOl_xlA4kpy8cRTznztQ",
        }
      ].concat([])}
    />
  )
}

export default Seo
