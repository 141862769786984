import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import {
  container,
  black,
  logo,
  navFlex,
  spBr
} from './nav.module.css'

const Nav = () => {
  return(
    <>
      <header className={container}>
        <h1>
          <Link to="/">
            <StaticImage
            alt="ほぐしのエリー"
            src="../images/logo_b_edited.jpg"
            className={logo}
            />
          </Link>
        </h1>
        <nav>
          <p className={black}>湘南鵠沼・片瀬江ノ島　<br className={spBr}/>こころとからだのメンテナンス</p>
          <ul className={navFlex}>
            <li><Link to='/#menu'>施術内容</Link></li>
            <li><Link to='/#contact'>ご予約 / お問い合わせ</Link></li>
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Nav
